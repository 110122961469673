import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 900px;
  width: 100%;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

export const Title = styled.h1`
  font-size: 5em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 130px 0 100px;
`;

export const Copy = styled.div`
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  box-sizing: border-box;
  p {
    font-size: 1.8em;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.45);
    color: #fff;
    strong {
      color: #fff;
      border-bottom: 2px solid rgba(255, 255, 255, 0.55);
    }
  }
`;
