/* eslint-disable */

export default [
  {
    Name: 'The MINDS Foundation',
    Address1: '',
    Address2: '',
    City: '',
    Zip: '',
    Phone: '424-MINDS-04',
    Website1: 'https://www.mindsfoundation.org',
    Website2: '',
    Email: 'help@mindsfoundation.org',
    Category: 'Organization',
    Country: 'India',
    Description:
      'If you are based in India: You can reach us directly via call or WhatsApp at +919033837227 24 hours a day, 7 days a week.',
  },
  {
    Name: 'MPOWER',
    Address1: '',
    Address2: '',
    City: 'Mumbai',
    Zip: '',
    Phone: '912223856228',
    Website1: 'https://www.mpowerminds.com/seekhelp',
    Website2: '',
    Email: 'info@mpowerminds.com',
    Category: 'Organization',
    Country: 'India',
    Description:
      'At Mpower, we proactively champion mental health causes, create awareness, advocate prevention and provide services through a holistic and multi-disciplinary approach.',
  },
  {
    Name: 'Live Love Laugh Foundation',
    Address1: '',
    Address2: '',
    City: 'Mumbai',
    Zip: '',
    Phone: '',
    Website1:
      'https://thelivelovelaughfoundation.org/helpline.html#helplinenumbers',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'India',
    Description:
      'The foundation seeks to champion the cause of mental health in India, with a focus on depression in particular. Besides spreading awareness, it also aims to support and carry out various on-ground activities',
  },
  {
    Name: 'Sneha',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '044-24640050, 044-24640060',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'India',
    Description:
      'Sneha is a suicide prevention organisation that offers unconditional emotional support for the depressed, desperate and the suicidal. Sneha offers total confidentiality for its callers, their volunteers are non-judgemental and the services offered by them are free.',
  },
  {
    Name: 'iCALL',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '022-25521111',
    Website1: '',
    Website2: '',
    Email: 'icall@tiss.edu',
    Category: 'Hotline',
    Country: 'India',
    Description:
      'CALL provides emotional support, information and referral services to individuals in psycho-social distress, across the life span and across different gender and sexual identities.',
  },
  {
    Name: 'Lifeline',
    Address1: '',
    Address2: '',
    City: 'Kolkata',
    Zip: '',
    Phone: '033-24637401, 033-24637432',
    Website1: '',
    Website2: '',
    Email: 'lifelinekolkata@gmail.com',
    Category: 'Hotline',
    Country: 'India',
    Description:
      'Lifeline offers a free tele-helpline providing emotional support to people who are in despair, depressed or suicidal. Face to face befriending with prior appointment is also available',
  },
  {
    Name: 'Sumaitri',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '011-23389090',
    Website1: '',
    Website2: '',
    Email: 'feelingsuicidal@sumaitri.net',
    Category: 'Hotline',
    Country: 'India',
    Description:
      'Sumaitri is a crisis intervention centre for the depressed, distressed and suicidal',
  },
  {
    Name: 'Samaritans Mumbai',
    Address1: '',
    Address2: '',
    City: 'Mumbai',
    Zip: '',
    Phone: '91 84229 84528, +91 84229 84529',
    Website1: '',
    Website2: '',
    Email: 'talk2samaritans@gmail.com',
    Category: 'Hotline',
    Country: 'India',
    Description:
      'Need someone to talk to? Call us if you are disturbed, stressed, depressed or suicidal. We provide complete anonymity and strict confidentiality. You can talk to us about your innermost thoughts without fear of being judged or criticized',
  },
  {
    Name: 'Care Corner',
    Address1: '',
    Address2: '',
    City: 'Singapore',
    Zip: '',
    Phone: '1800 3535 800',
    Website1: 'https://www.carecorner.org.sg/our-services',
    Website2: '',
    Email: 'ccs@carecorner.org.sg',
    Category: 'Hotline/Organization',
    Country: 'Singapore',
    Description:
      'Counseling hotline and Building hope and promoting well-being of individuals and families in community through social and health care services.',
  },
  {
    Name: 'Oogachaga',
    Address1: '',
    Address2: '',
    City: 'Singapore',
    Zip: '',
    Phone: '6226 2002',
    Website1: 'https://oogachaga.com/hotline-whatsapp-email-counselling',
    Website2: '',
    Email: 'care@oogachaga.com ',
    Category: 'Hotline/Organization',
    Country: 'Singapore',
    Description:
      'LGBTQ Peer-led Suppport Group and Hotliine. These confidential services provide an active listening ear and emotional support for people who would like to share about issues troubling them, such as sexuality, identity, relationships, mental health and sexual health.We also provide information on LGBTQ+ community resources and LGBTQ-affirming professional resources.',
  },
  {
    Name: 'Samaritans of Singapore',
    Address1: '',
    Address2: '',
    City: 'Singapore',
    Zip: '',
    Phone: '1800-221-444',
    Website1: 'https://sos.org.sg',
    Website2: '',
    Email: 'pat@sos.org.sg',
    Category: 'Hotline',
    Country: 'Singapore',
    Description:
      'Samaritans of Singapore (SOS) is dedicated to providing confidential emotional support to individuals facing a crisis, thinking about suicide or affected by suicide. Non-religious and not for profit, our work focuses on crisis intervention and suicide prevention.',
  },
  {
    Name: 'Institute of Mental Health',
    Address1: '',
    Address2: '',
    City: 'Singapore',
    Zip: '',
    Phone: '6389 2222',
    Website1: 'https://www.imh.com.sg/wellness/',
    Website2: '',
    Email: 'enquiry@imh.com.sg',
    Category: 'Hotline',
    Country: 'Singapore',
    Description:
      'IMH offers a comprehensive range of psychiatric, rehabilitative and counselling services for children, adolescents, adults, and the elderly',
  },
  {
    Name: 'Merak Clini',
    Address1: '',
    Address2: '',
    City: 'Bangkok',
    Zip: '',
    Phone: '\n02-589-4582,084-733-0444',
    Website1: 'http://www.merakclinic.com',
    Website2: '',
    Email: 'merakclinic@hotmail.com',
    Category: 'Organization',
    Country: 'Thailand',
    Description: 'child and adolescent mental health clinic.',
  },
  {
    Name: 'The Samaritans of Thailand ',
    Address1: '',
    Address2: '',
    City: 'Bangkok',
    Zip: '',
    Phone: '02-713-6793 (Thai), 02-713-6791 (English)',
    Website1: 'http://www.samaritansthai.com/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Thailand',
    Description:
      'Samaritans is an association that serves as a friend to talk on the phone.',
  },
  {
    Name: 'Silakbo PH',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone: '',
    Website1: 'http://www.silakbo.ph/help/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'Philippines',
    Description:
      'Silakbo PH is an effort by the Filipino youth to uplift stories in the context of mental health awareness',
  },
  {
    Name: 'Knit Mental Health',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone: '',
    Website1: 'https://www.facebook.com/pg/KnitMentalHealth',
    Website2: '',
    Email: 'knit.mh.ph@gmail.com',
    Category: 'Organization',
    Country: 'Philippines',
    Description:
      'Knit Philippines is a company that recognizes the benefits of general mental health care and utilizes public health models in providing innovative solutions to mental health veering away from the traditional and outdated clinical systems.',
  },
  {
    Name: 'The Natasha Goulbourn Foundation',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone: '',
    Website1: 'http://www.ngf-hope.org',
    Website2: '',
    Email: 'ngfoundation@gmail.com',
    Category: 'Organization',
    Country: 'Philippines',
    Description:
      'non-profit organization in the Philippines dedicated to bringing depression to light through the use of educational lectures, confidential crisis lines and referrals to partner mental health professionals.',
  },
  {
    Name: 'Crisis Line',
    Address1: 'Crisis Line',
    Address2: 'Crisis Line',
    City: 'Manila',
    Zip: 'Crisis Line',
    Phone: '8937603 / 09178001123 / 09228938944',
    Website1: 'https://in-touch.org',
    Website2: '',
    Email: 'intouch@in-touch.org',
    Category: 'Hotline/Organization',
    Country: 'Philippines',
    Description:
      'In Touch Community Services is a leading provider of Mental Health Services in the Philippines. Established in 1980, it is a volunteer-driven organization dedicated to promoting mental health and offering care and treatment to persons suffering from mental and emotional distress.',
  },
  {
    Name: 'Tawag Paglaum-Centro Bisaya:',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone: 'Smart/Sun: 0939-9375433 / 0939-9365433; Globe/TM: 0927-6541629',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Philippines',
    Description:
      '(24/7 suicide, depression, and emotional crisis intervention hotline)',
  },
  {
    Name: 'Mood Harmony',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone: '(02) 844-2941',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Philippines',
    Description: '(Makati Medical Center Support Group for Mood Disorders)',
  },
  {
    Name: 'Hopeline',
    Address1: '',
    Address2: '',
    City: 'Manila',
    Zip: '',
    Phone:
      'PLDT: (02) 804-4673; Globe: (0917) 558-4673; Toll-free for Globe/TM: 2919',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Philippines',
    Description:
      'Hopeline is a 24/7 suicide prevention and crisis support helpline.',
  },
  {
    Name: 'Kauban',
    Address1: '',
    Address2: '',
    City: 'Cebu',
    Zip: '',
    Phone: '',
    Website1: 'https://www.facebook.com/KaubanCebu/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'Philippines',
    Description:
      'The University of San Carlos, through its Community Extension Service (CES), launched KAUBAN, a support group for people manifesting symptoms of depression, anxiety, and suicidal ideations. The first mental health support group in Cebu',
  },
  {
    Name: 'Wellbeing Cluster',
    Address1: '',
    Address2: '',
    City: 'Cebu',
    Zip: '',
    Phone: '',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'Philippines',
    Description: 'Network of Mental Health Organizations in Cebu',
  },
  {
    Name: 'Tawag Paglaum-Centro Bisaya:',
    Address1: '',
    Address2: '',
    City: 'Cebu',
    Zip: '',
    Phone: 'Smart/Sun: 0939-9375433 / 0939-9365433; Globe/TM: 0927-6541629',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Philippines',
    Description:
      '(24/7 suicide, depression, and emotional crisis intervention hotline)',
  },
  {
    Name: 'Samaritan Befrienders HK',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '2389-2222',
    Website1: 'https://www.sbhk.org.hk/hotline_service.php',
    Website2: '',
    Email: 'sbhkinfo@sbhk.org.hk ',
    Category: 'Hotline/Organization',
    Country: 'Hong Kong',
    Description:
      'The Samaritan Befrienders Hong Kong (SBHK) was formed in 1960 by a group of volunteers. Starting from 24-hour emotional support hotline, they now extend their services to suicide crisis intervention, life education and professional training ',
  },
  {
    Name: 'Mind HK',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '',
    Website1: 'https://www.mind.org.hk/',
    Website2: '',
    Email: 'hello@mind.org.hk',
    Category: 'Organization',
    Country: 'Hong Kong',
    Description:
      'We provide online support and interventions, based on global best practice, to empower anyone experiencing a mental health problem.',
  },
  {
    Name: 'Hospital Authority Mental Health Direct',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '2466-7350',
    Website1: '',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Hong Kong',
    Description:
      'The Hospital Authority and the Mental Health Association of Hong Kong maintain 24-hour hotlines for people who need help with mental health problems.',
  },
  {
    Name: 'Lifeline Hong Kong',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '2382-0000',
    Website1: 'https://www.sps.org.hk/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Hong Kong',
    Description:
      'Since its inception in 1995, the "Lifeline" has helped more than 520,000 people',
  },
  {
    Name: 'Youth Outreach',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '90881023',
    Website1: 'https://www.yo.org.hk/en/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Hong Kong',
    Description:
      'Youth Outreach is dedicated to helping at-risk youth grow into responsible members of our community',
  },
  {
    Name: 'The Samaritans  ',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '2389-60000',
    Website1: 'https://samaritans.org.hk/about-us/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Hong Kong',
    Description:
      'The Samaritans is a non-profit, non-religious organisation giving confidential emotional support to people who are suicidal or are in general distress. The service is provided to anyone regardless of age, creed, ethnicity, gender or sexual orientation.',
  },
  {
    Name: 'Joyful Foundation',
    Address1: '',
    Address2: '',
    City: 'Hong Kong',
    Zip: '',
    Phone: '23012303',
    Website1: 'https://www.jmhf.org/jmhf-web/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Hong Kong',
    Description:
      'To provide a wide range of information, assistance, and ways of treatment, referrals and face-to-face counseling services to people suffering from emotional disorders so as to reduce the number of suffering people by providing them proper treatment in time.',
  },
  {
    Name: 'Into The Light',
    Address1: '',
    Address2: '',
    City: 'Jakarta',
    Zip: '',
    Phone: '',
    Website1: 'https://www.intothelightid.org/',
    Website2: '',
    Email: 'contact@intothelightid.org',
    Category: 'Organization',
    Country: 'Indonesia',
    Description:
      'Into The Light Indonesia Suicide Prevention Community for Advocacy, Research, and Education (SP-CARE) is a community of young people who focus as a center for suicide prevention education and mental health education in Indonesia.',
  },
  {
    Name: 'Save Yourselves.id',
    Address1: '',
    Address2: '',
    City: 'Jakarta',
    Zip: '',
    Phone: '',
    Website1: 'http://saveyourselves.id/index#sys-hotline',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'Indonesia',
    Description:
      'Saveyourselves is a community that aims to raise awareness about mental health. Our three main services are education, supportive counseling, and suicide prevention',
  },
  {
    Name: 'Loveline Counseling Center',
    Address1: '',
    Address2: '',
    City: 'Seoul',
    Zip: '',
    Phone: '(2) 715 8600,  (2) 716 8600,  (2) 717 8600,  (2) 718 8600',
    Website1: 'https://counsel24.com',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'South Korea',
    Description:
      'The Love Counseling Center was established in 1981 as a specialized social welfare counseling organization.',
  },
  {
    Name: 'Lifeline Korea',
    Address1: '',
    Address2: '',
    City: 'Seoul',
    Zip: '',
    Phone: '1577-0199',
    Website1: 'http://www.lifeline.or.kr/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'South Korea',
    Description:
      "Korea's first telephone consulting agency - 24 hours a day, 365 days a year. To spreads respect for life and practice suicide prevention.",
  },
  {
    Name: 'Korea Suicide Prevention Center',
    Address1: '',
    Address2: '',
    City: 'Seoul',
    Zip: '',
    Phone: '82 2-2203-0053',
    Website1: 'http://www.spckorea.or.kr/index.php',
    Website2: '',
    Email: 'spc@spckorea.or.kr',
    Category: 'Hotline',
    Country: 'South Korea',
    Description:
      'The establishment and operation of the Center for the Prevention of Central Suicide began in 2011.',
  },
  {
    Name: 'Hopeclick',
    Address1: '',
    Address2: '',
    City: 'Seoul',
    Zip: '',
    Phone: '',
    Website1: 'http://www.hopeclick.or.kr',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'South Korea',
    Description: 'National Hotline',
  },
  {
    Name: 'Lifeline Osaka',
    Address1: '',
    Address2: '',
    City: 'Osaka',
    Zip: '',
    Phone: '03-5774-0992',
    Website1: 'https://telljp.com/lifeline/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Japan',
    Description:
      'Every day for 45 years TELL Lifeline has been providing important connections and support to people all across Japan.',
  },
  {
    Name: 'Kansai Life Phone',
    Address1: '',
    Address2: '',
    City: 'Osaka',
    Zip: '',
    Phone: '06-6309-1121',
    Website1: 'https://kaindnew.com/',
    Website2: '',
    Email: 'kaind@age.ac',
    Category: 'Hotline',
    Country: 'Japan',
    Description: 'Telephone consultation since 1973',
  },
  {
    Name: 'Befrienders Osaka',
    Address1: '',
    Address2: '',
    City: 'Osaka',
    Zip: '',
    Phone: '81 (0) 6 4395 4343',
    Website1: 'http://www.spc-osaka.org/about/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'Japan',
    Description:
      'We are a volunteer group whose purpose is to provide emotional support to those who are thinking or committing suicide. We mainly perform activities such as telephone consultation, meeting of self-death bereaved sharing and lecture',
  },
  {
    Name: 'Stonewall Japan',
    Address1: '',
    Address2: '',
    City: 'Osaka',
    Zip: '',
    Phone: '',
    Website1: 'https://stonewalljapan.org',
    Website2: '',
    Email: 'info@stonewalljapan.org',
    Category: 'Organization',
    Country: 'Japan',
    Description:
      'Stonewall Japan is a community organisation providing support for, and enhancing the lives of international Lesbian, Gay, Bisexual, Transgender, Queer (LGBTQIA+) people in Japan desiring an outlet for expressing their gender and sexuality',
  },
  {
    Name: 'Nagoya Life Phone',
    Address1: '',
    Address2: '',
    City: 'Nagoya',
    Zip: '',
    Phone: '052-931-4343',
    Website1: 'http://www.nagoya-inochi.jp/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Japan',
    Description:
      'The Nagoya Life Phone is a phone consultation that can be used "anytime," "anywhere," and "everyone."',
  },
  {
    Name: 'Befrienders Nagoya',
    Address1: '',
    Address2: '',
    City: 'Nagoya',
    Zip: '',
    Phone: '052-870-9090',
    Website1: 'https://www.befrienders-jpn.org/aichi/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'Japan',
    Description: 'Telephone consultation since 1985',
  },
  {
    Name: 'Befrienders Tokyo',
    Address1: '',
    Address2: '',
    City: 'Tokyo',
    Zip: '',
    Phone: '03 5286 9090',
    Website1: 'http://www.befrienders-jpn.org',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'Japan',
    Description: 'Telephone consultation since 1971',
  },
  {
    Name: 'Japan Helpline',
    Address1: '',
    Address2: '',
    City: 'Tokyo',
    Zip: '',
    Phone: '0570 000 911',
    Website1: 'https://www.jhelp.com/en/jhlp.html',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Japan',
    Description:
      "Japan's only 24 hour non-profit, nationwide emergency assistance service for international residents",
  },
  {
    Name: 'TELL Lifeline',
    Address1: '',
    Address2: '',
    City: 'Tokyo',
    Zip: '',
    Phone: '03-5774-0992',
    Website1: 'http://telljp.com/lifeline/tell-chat/',
    Website2: '',
    Email: 'event@telljp.com',
    Category: 'Hotline/Organization',
    Country: 'Japan',
    Description:
      'Every day for 45 years TELL Lifeline has been providing important connections and support to people all across Japan.country’s growing mental health care needs.',
  },
  {
    Name: 'Samaritans',
    Address1: '',
    Address2: '',
    City: 'London',
    Zip: '',
    Phone: '116 123',
    Website1: 'https://www.samaritans.org',
    Website2: '',
    Email: 'jo@samaritans.org',
    Category: 'Hotline',
    Country: 'England',
    Description:
      'Provides confidential, non-judgemental emotional support for people experiencing feelings of distress or despair, including those that could lead to suicide. You can phone, email, write a letter or in most cases talk to someone face to face.',
  },
  {
    Name: 'Mind Infoline',
    Address1: '',
    Address2: '',
    City: 'London',
    Zip: '',
    Phone: '0300 123 3393, 86463 (Text)',
    Website1: 'https://www.mind.org.uk/information-support/helplines',
    Website2: '',
    Email: 'info@mind.org.uk',
    Category: 'Hotline/Organization',
    Country: 'England',
    Description:
      'With support and understanding, Mind enables people to make informed choices. The Infoline gives information on types of mental health problems, where to get help, drug treatments, alternative therapies and advocacy. Mind works in partnership with around 140 local Minds providing local mental health services.',
  },
  {
    Name: 'Time to change',
    Address1: '',
    Address2: '',
    City: 'London',
    Zip: '',
    Phone: '',
    Website1: 'https://www.time-to-change.org.uk/',
    Website2: '',
    Email: 'info@time-to-change.org.uk',
    Category: 'Organization',
    Country: 'England',
    Description:
      "Time to Change is a growing social movement working to change the way we all think and act about mental health problems. We've already reached millions of people and begun to improve attitudes and behaviour.",
  },
  {
    Name: 'Saneline',
    Address1: '',
    Address2: '',
    City: 'London',
    Zip: '',
    Phone: '0300 304 7000',
    Website1: 'http://www.sane.org.uk/what_we_do/support/textcare/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'England',
    Description:
      'SANEline is a national out-of-hours mental health helpline offering specialist emotional support, guidance and information to anyone affected by mental illness, including family, friends and carers',
  },
  {
    Name: 'CALM',
    Address1: '',
    Address2: '',
    City: 'London',
    Zip: '',
    Phone: '0800 58 58 58, https://www.thecalmzone.net/help/webchat/',
    Website1: 'https://www.thecalmzone.net/help/get-help/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'England',
    Description:
      'Our helpline is for men in the UK who are down or have hit a wall for any reason, who need to talk or find information and support.',
  },
  {
    Name: 'Inochi no Denwa',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '',
    Website1: 'https://www.inochinodenwa.org/lifeline.php',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Japan',
    Description:
      'The main hotline in Japan is called Inochi no Denwa, which means lifeline in Japanese. There is a branch in every prefecture.',
  },
  {
    Name: 'Crisis Text Line',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: 'Text CONNECT to 741741',
    Website1: 'https://www.crisistextline.org',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'US',
    Description:
      'Crisis Text Line is free, 24/7 support for those in crisis, connecting people in crisis to trained Crisis Counselors. Our first priority is helping people move from a hot moment to a cool calm, guiding you to create a plan to stay safe and healthy. YOU = our priority.',
  },
  {
    Name: 'Trevor Project',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1-866-488-7386',
    Website1:
      'https://www.thetrevorproject.org/get-help-now/#sm.001a4ld1g11kweulwoc292mb9jecg',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'US',
    Description:
      'Text, Chat or Call. The Trevor Project is the leading national organization providing crisis intervention and suicide prevention services to lesbian, gay, bisexual, transgender, queer & questioning (LGBTQ) young people under 25.',
  },
  {
    Name: 'Trans Lifeline',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '877-565-8860',
    Website1: 'http://www.translifeline.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'US',
    Description:
      'Trans Lifeline is a national trans-led 501(c)(3) organization dedicated to improving the quality of trans lives by responding to the critical needs of our community with direct service, material support, advocacy, and education.',
  },
  {
    Name: 'Saptel',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '(55) 5259-8121',
    Website1: 'http://www.saptel.org.mx/index.html',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Mexico',
    Description:
      'SAPTEL is a mental health and Distance Medicine service with 30 years of operation. SAPTEL is a professional program attended by selected, trained, trained and supervised psychologists who provide counseling, referral, psychological support, psychotherapeutic counseling and emotional crisis intervention through the telephone for free.',
  },
  {
    Name: 'Centro de Valorização da Vida',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '188',
    Website1: 'https://www.cvv.org.br/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Brazil',
    Description:
      'Text, Chat or email. An emotional and suicidal prevention support NGO founded in 1962 in São Paulo, Brazil, and recognized as Federal Public Utility in 1973. It offers voluntary and free support, with all communications being confidential.',
  },
  {
    Name: 'Lifeline',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '131114',
    Website1: 'https://www.lifeline.org.au/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Australia',
    Description:
      'Call or Chat. Lifeline is a national charity providing all Australians experiencing a personal crisis with access to 24 hour crisis support and suicide prevention services.',
  },
  {
    Name: 'kidshelpline',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1800551800',
    Website1: 'https://kidshelpline.com.au/young-adults',
    Website2: '',
    Email: 'counsellor@kidshelpline.com.au',
    Category: 'Hotline',
    Country: 'Australia',
    Description:
      'Call, Email or Chat. Kids Helpline is Australia’s only free, private and confidential 24/7 phone and online counselling service for young people aged 5 to 25.',
  },
  {
    Name: 'Beyond Blue',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1300224636',
    Website1: 'https://www.beyondblue.org.au/',
    Website2: '',
    Email: 'Email us',
    Category: 'Hotline',
    Country: 'Australia',
    Description:
      'Call, Email or Chat.  Beyond Blue provides information and support to help everyone in Australia achieve their best possible mental health, whatever their age and wherever they live.',
  },
  {
    Name: 'Batyr',
    Address1: '',
    Address2: '',
    City: 'Sydney',
    Zip: '',
    Phone: '61 402 576 114',
    Website1: 'https://www.batyr.com.au/',
    Website2: '',
    Email: 'hello@batyr.com.au',
    Category: 'Organization',
    Country: 'Australia',
    Description:
      'batyr is a for purpose preventative mental health organisation, created and driven by young people, for young people.',
  },
  {
    Name: 'Taiwan Lifelline International',
    Address1: '',
    Address2: '',
    City: 'Taiwan',
    Zip: '',
    Phone: '1995',
    Website1: 'http://www.life1995.org.tw/content.asp?id=6',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Taiwan',
    Description: 'National hotlines throughout Taiwan',
  },
  {
    Name: 'Jack.org',
    Address1: '',
    Address2: '',
    City: 'Toronto',
    Zip: '',
    Phone: '(416) 425-2494',
    Website1: 'https://jack.org/Home',
    Website2: '',
    Email: 'hello@jack.org',
    Category: 'Organization',
    Country: 'Canada',
    Description:
      "Jack.org is Canada's only charity training and empowering young leaders to revolutionize mental health in every province and territory",
  },
  {
    Name: 'Crisis Text Line',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: 'Text HOME to 686868',
    Website1: 'https://www.crisistextline.ca/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Canada',
    Description:
      'service partnership between Kids Help Phone and U.S.-based crisis line and technology pioneer Crisis Text Line, providing young people in Canada with the first ever, 24/7, free nationwide texting service',
  },
  {
    Name: 'Kids Help Phone',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1-800-668-6868.',
    Website1: 'https://kidshelpphone.ca/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Canada',
    Description:
      'Call, Text or email. Kids Help Phone is Canada’s only 24/7, national support service',
  },
  {
    Name: 'Crisis Services Canada',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1-833-456-4566',
    Website1: 'http://www.crisisservicescanada.ca/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Canada',
    Description:
      'Call, Text or Chat. Crisis Services Canada (CSC) is a collaboration of distress and crisis centres from across Canada, offering Canada’s first nationally available, regionally delivered suicide prevention service.',
  },
  {
    Name: 'youthspace',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: 'Text 778-783-0177',
    Website1: 'https://www.youthspace.ca/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Canada',
    Description:
      'Chat or Text. Youthspace is a place for any youth across Canada, under 30, that is experiencing any sort of crisis to come and talk. ',
  },
  {
    Name: 'Trans Lifeline',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '877-330-6366',
    Website1: 'http://www.translifeline.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Canada',
    Description:
      'Trans Lifeline is a national trans-led 501(c)(3) organization dedicated to improving the quality of trans lives by responding to the critical needs of our community with direct service, material support, advocacy, and education.',
  },
  {
    Name: 'TelefonSeelsorge',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '0800; 111 0 111 · 0800; 111 0 222 · 116 123',
    Website1: 'https://www.telefonseelsorge.de/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Germany',
    Description: 'National Hotline in Germany',
  },
  {
    Name: 'Nummer gegen Kummer',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '116111',
    Website1: 'https://www.nummergegenkummer.de/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Germany',
    Description:
      'Nummer gegen Kummer eV (NgK) is the umbrella organization of the largest free telephone counseling service for children, adolescents and parents throughout Germany.',
  },
  {
    Name: 'Befrienders Kuala Lumpur',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '603-79568145',
    Website1: 'https://www.befrienders.org.my/',
    Website2: '',
    Email: 'sam@befrienders.org.my',
    Category: 'Hotline',
    Country: 'Malaysia',
    Description:
      'Befrienders is a not-for-profit organisation providingemotional support 24 hours a day, 7 days a week, to people who are lonely, in distress, in despair, and having suicidal thoughts - without charge.',
  },
  {
    Name: 'Life Line Malaysia',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '603-4265 7995',
    Website1: 'http://lifeline.org.my',
    Website2: '',
    Email: 'counselling@lifeline.org.my',
    Category: 'Hotline',
    Country: 'Malaysia',
    Description:
      'The Malaysia Lifeline Association was established in 1989 and registered in 1993 as a non-religious, non-political, non-profit organization that provides counselling services, social care, and social education to the general public.',
  },
  {
    Name: 'Befrienders Penang',
    Address1: '',
    Address2: '',
    City: 'Penang',
    Zip: '',
    Phone: '604-2815161 / 604-2811108',
    Website1: 'http://www.befpen.org/',
    Website2: '',
    Email: 'pat@befpen.org',
    Category: 'Hotline/Organization',
    Country: 'Malaysia',
    Description:
      'Befrienders provides confidential emotional support for people who are experiencing feelings of distress or despair, including those which may lead to suicide',
  },
  {
    Name: 'S.O.S. Amitie',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '09 72 39 40 50',
    Website1: 'https://www.sos-amitie.com/web/internet/chat',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'France',
    Description:
      'Call or Chat. SOS Amitié is a Federation of 44 regional associations with 50 telephone, messaging and chat rooms',
  },
  {
    Name: 'Suicide Listening',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '01-45-39-40-00',
    Website1: 'https://suicideecoute.pads.fr/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'France',
    Description:
      'an organization founded in 1994 and dedicated to suicide prevention, provides a 24-hour “listening line”',
  },
  {
    Name: 'SOS Help',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '01 46 21 46 46',
    Website1: 'http://www.soshelpline.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'France',
    Description:
      "The listening team at SOS Help is made up entirely of volunteers. We're trained by professional psychologists, who provide continued training and support, but who do not work on the line. We're trained to listen to you, and not to judge, label or diagnose you.",
  },
  {
    Name: "Olsztynski Telefon Zaufania 'Anonimowy Przyjaciel",
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '89 19288; 89 527 00 00',
    Website1: 'http://telefonzaufania.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Poland',
    Description:
      'Phone support is support in a difficult life situation, related to illness, family crisis, lack of means of subsistence.We help people who are suffering, lonely, sometimes desperate, those who have no one to understand, accept and give spiritual suppor',
  },
  {
    Name: 'Telefon Zaufania dla Dzieci i Młodzieży',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '116 111',
    Website1: 'https://116111.pl/napisz',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Poland',
    Description: 'We help young people deal with difficult matters.',
  },
  {
    Name: 'Suppport Line',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '800 70 22 22',
    Website1: 'https://liniawsparcia.pl/kontakt/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Poland',
    Description:
      '24-hour line and free for callers. The psychologists of the ITAKA Foundation, providing advice and directing callers to the appropriate help center in their region, are on call, e-mail and cha',
  },
  {
    Name: 'Telefono Amico Italia',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '199 284 284',
    Website1: 'http://www2.telefonoamico.it/webcall-tai/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Italy',
    Description:
      'voluntary organization that since 1967 has been listening to anyone who feels loneliness, anguish, sadness, despondency, anger, discomfort and feels the need to share these emotions with a friendly voice',
  },
  {
    Name: 'Samaritans - ONLUS',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '800 86 00 22 (landline); 06 77208977 (mobile)',
    Website1: 'http://www.samaritansonlus.org/chi-siamo/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Italy',
    Description:
      'When living becomes too heavy and there is no one to turn to, when the problems seem unbearable, the Samaritans are ready to give you emotional support, in absolute confidentiali',
  },
  {
    Name: 'TELEFONO AZZURRO',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1.96.96',
    Website1: 'http://www.azzurro.it/chat',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Italy',
    Description:
      'Call or Chat. Telefono Azzurro  promotes total respect for the rights of children and adolescents. With its activities every day promotes their potential for growth and protects them from abuse and violence that can jeopardize their well-being and growth path',
  },
  {
    Name: '113 Zelfmoord Preventie',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '0900-0113',
    Website1: 'https://www.113.nl/ik-denk-aan-zelfmoord/crisislijn',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Netherlands',
    Description: 'Call or Chat. national suicide prevention organization',
  },
  {
    Name: 'De Luisterlijn',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '0900 0767',
    Website1: 'https://www.deluisterlijn.nl/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Netherlands',
    Description:
      'is the national organization that offers anonymous support day and night by telephone, e-mail and chat. This is done by 1,500 volunteers throughout the countr',
  },
  {
    Name: 'Samen Sterk Zonder Stigma',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '033 - 3032100',
    Website1: 'https://www.samensterkzonderstigma.nl/',
    Website2: '',
    Email: 'info@samensterkeloosstigma.nl',
    Category: 'Organization',
    Country: 'Netherlands',
    Description:
      'Samen Sterk Zonder Stigma is working on a society in which psychological problems can be discussed',
  },
  {
    Name: 'MIND',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '90101',
    Website1: 'https://mind.se/hitta-hjalp/sjalvmordslinjen/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Sweden',
    Description:
      'Call, Chat or Email. Mind is an independent non-profit organization working for mental health',
  },
  {
    Name: '1177 Vardguiden',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '0771-22 00 60',
    Website1: 'https://www.1177.se/Stockholm/Om-1177/Om-Hjalplinjen/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Sweden',
    Description: 'National Hotline',
  },
  {
    Name: 'Bris',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '116 111',
    Website1:
      'https://www.bris.se/for-barn-och-unga/logga-in/?ReturnUrl=%2ffor-barn-och-unga%2fchatten%2f',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'Sweden',
    Description:
      "Children's Rights in Society, is one of Sweden's leading children's rights organizations, which every day struggle for a better society for children",
  },
  {
    Name: 'Hjärnkoll',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '08-120 488 20',
    Website1: 'https://hjarnkoll.se/',
    Website2: '',
    Email: 'info@hjarnkoll.se',
    Category: 'Organization',
    Country: 'Sweden',
    Description:
      'Hjärnkoll mission is to create a more open conversation climate around mental illness in the workplace, and to help managers and employees to better manage and prevent mental illness.',
  },
  {
    Name: 'En Af Os',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '45 30 45 01 04',
    Website1: 'http://www.en-af-os.dk/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'Denmark',
    Description:
      'ONE OF US vision is to improve life for all by promoting inclusion and combatting discrimination connected to mental illness in Denmark',
  },
  {
    Name: 'National Suicide Prevention Hotline',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '1-800-273-TALK (8255)',
    Website1: 'https://suicidepreventionlifeline.org/',
    Website2: '',
    Email:
      'http://chat.suicidepreventionlifeline.org/GetHelp/LifelineChat.aspx?_ga=2.170036964.558126.1554640102-1212614431.1554640102',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'The National Suicide Prevention Lifeline is a national network of local crisis centers that provides free and confidential emotional support to people in suicidal crisis or emotional distress 24 hours a day, 7 days a week.',
  },
  {
    Name: "The Women's Center",
    Address1: '',
    Address2: '',
    City: 'Washington DC',
    Zip: '',
    Phone: '(202) 293-4580; 703) 281-2657 ',
    Website1: 'https://thewomenscenter.org/about-us/',
    Website2: '',
    Email: 'questions@thewomenscenter.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'For over forty years, The Women’s Center has provided mental health counseling, support and education to the metropolitan area to help people live healthy, stable and productive lives.Since The Women’s Center was founded in 1974, we have grown to include service to women, men, families, young adults and children',
  },
  {
    Name: "Mary's Center",
    Address1: '',
    Address2: '',
    City: 'Washington DC',
    Zip: '',
    Phone: '202-483-8196',
    Website1: 'https://www.maryscenter.org/SBMH',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      "Mary's Center, founded in 1988, is a Community Health Center that provides health care, family literacy and social services to individuals whose needs too often go unmet by the public and private systems.  The Mary’s Center School Based Mental Health (SBMH) Program supports children, families, and communities by promoting behavioral wellness. Established in 2014, SBMH currently partners with 19 public schools in Wards 1, 4, and 5 in Washington, DC.  ",
  },
  {
    Name: 'Amazing Love Health Services',
    Address1: '',
    Address2: '',
    City: 'Washington DC',
    Zip: '',
    Phone: '202-388-8500',
    Website1: 'http://www.alhs-health.com/services/',
    Website2: '',
    Email: 'info@alhs-health.com',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'we offer a comprehensive, integrated set of health care, education, and social services to help individuals and families achieve physical and mental health, overcome the life challenges they face, and enjoy the same opportunities as any other citizen',
  },
  {
    Name: 'Latin American Youth Center',
    Address1: '',
    Address2: '',
    City: 'Washington DC',
    Zip: '',
    Phone: '(202) 319-2257',
    Website1:
      'http://www.layc-dc.org/what-we-do/health-wellness/mental-health-treatment/',
    Website2: '',
    Email: 'bhreferrals@layc-dc.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The program provides outpatient mental health treatment. Services integrate the strengths of each individual and family relationships, respond to individual cultural differences, and incorporate each individual’s needs into treatment plans.',
  },
  {
    Name: 'National Alliance of Mental Illness',
    Address1: '',
    Address2: '',
    City: 'Washington DC',
    Zip: '',
    Phone: '(202) 546-0646',
    Website1: 'http://www.namidc.org/',
    Website2: '',
    Email: 'namidc@namidc.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      "NAMI is the nation's largest grassroots organization providing advocacy, education, support, and public awareness so that all individuals and families affected by mental illness can build better lives.",
  },
  {
    Name: 'Center for Families and Relationships',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '215-537-5367',
    Website1: 'https://www.cfarcounseling.org/',
    Website2: '',
    Email: 'cfar@cfarcounseling.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Not for profit counseling center serving the needs of\nindividuals, couples, families and children.',
  },
  {
    Name: 'Gaudenzia',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '(215) 238-2150',
    Website1: 'http://www.gaudenzia.org/services',
    Website2: '',
    Email: 'http://www.gaudenzia.org/get_help_now',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'Services include specialized programs for pregnant and parenting mothers, adolescents, and people suffering from co-occurring mental illness and substance abuse.',
  },
  {
    Name: 'Horizon House',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '215.386.3838',
    Website1: 'https://www.hhinc.org/behavioral-health-services/',
    Website2: '',
    Email: 'info@hhinc.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'At Horizon House, our community-based treatment services incorporate a variety of customized needs-based services to support people living with ongoing mental illness.',
  },
  {
    Name: 'Interact',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '(215) 487-1330',
    Website1:
      'http://intercommunityaction.org/behavioral-health-services/childrens-home-community/',
    Website2: '',
    Email: 'greenridge@intercommunityaction.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Interact’s Behavioral Health Division partners with people in recovery from mental health and addiction challenges and their supporters with the goal of enriching their lives through improving their health and wellness and fostering hope in realizing their full potential.',
  },
  {
    Name: 'Mental Health Partnerships',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '215-751-1800',
    Website1: 'https://www.mentalhealthpartnerships.org/get-help',
    Website2: 'https://www.mentalhealthpartnerships.org/contact',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Mental Health Partnerships was founded, and continues to thrive, on the basic principle that people with mental health conditions can and do recover because they have the resilience to direct their own journeys in overcoming significant adversity.',
  },
  {
    Name: 'Healthy Minds Philadelphia',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '(215) 686-4420',
    Website1: 'https://healthymindsphilly.org/en/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'HealthyMindsPhilly.org is an online tool and resource designed to support and improve the mental health and well-being of all Philadelphians, regardless of zip code, insurance or income status.',
  },
  {
    Name: 'CONTACT Helpline',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '215.355.6000',
    Website1:
      'https://mindingyourmind.org/wp-content/uploads/2014/02/Contact-Helpline.jpg',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Provides free, anonymous, and confidential telephone services to individuals who are contemplating suicide, struggling with life’s challenges, or in need of someone to listen.',
  },
  {
    Name: 'Center for Hope and Health',
    Address1: '',
    Address2: '',
    City: 'Philadelphia',
    Zip: '',
    Phone: '866-739-3083',
    Website1: 'https://www.centerforhopeandhealth.com/the-chh-difference/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'CHH is a private practice that offers specialized outpatient mental health treatment to adolescents and adults',
  },
  {
    Name: 'Samaritan Hope, Greater Boston',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(877) 870-4673',
    Website1: 'https://samaritanshope.org/our-services/247-crisis-services/',
    Website2: '',
    Email: '',
    Category: 'Hotline  ',
    Country: 'USA',
    Description: 'Call or Text. 24/7 Crisis Helpline',
  },
  {
    Name: 'Riverside Community Care',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '800-529-5077',
    Website1:
      'https://www.riversidecc.org/child-family-services/emergency-services/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'Each year Riverside Community Care provides more than 40,000 children, adolescents, and adults with a broad range of behavioral healthcare and human services.  ',
  },
  {
    Name: 'Aspire Health Alliance',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617-774-6036',
    Website1: 'https://www.aspirehealthalliance.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'Aspire Health Alliance provides a continuum of care that is proactive, coordinated, available through multiple access points, and integrated with medical care',
  },
  {
    Name: 'Families for Depression Awareness',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(781) 890-0220',
    Website1: 'http://www.familyaware.org/',
    Website2: '',
    Email: 'info@familyaware.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'We focus on family members and friends of those who are depressed, and caregivers that usually operate with little or no guidance or support.',
  },
  {
    Name: 'Wayside Youth and Families',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '508-879-9800',
    Website1: 'https://www.waysideyouth.org/aboutus/aboutusoverview/#!',
    Website2: '',
    Email: 'https://www.waysideyouth.org/contact-us/',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'non-profit human service agency with counseling programs, a residential campus and family-based outreach services.',
  },
  {
    Name: 'National Alliance on Mental Illness, Boston',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '1-800-981-4357',
    Website1: 'https://namiboston.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'We represent the voices of those living with mental illness in the areas of advocacy and the development of peer-run supports and services',
  },
  {
    Name: 'Boston Emergency Services Team',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '1-800-981-4357',
    Website1:
      'http://northsuffolk.org/services/emergency-services/boston-emergency-services-team/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Boston Emergency Services team (B.E.S.T.), under the leadership of Boston Medical Center and with the support of the Massachusetts Behavioral Health Partnership, provides a comprehensive, highly integrated system of crisis evaluation and treatment services to the greater Boston area;',
  },
  {
    Name: 'North Suffolk Mental Health Association',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(617) 569-3189',
    Website1:
      'http://northsuffolk.org/services/adult-services/outpatient-services/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'counseling centers provide individual, group and family therapy as well as psychiatric and medication services.',
  },
  {
    Name: 'The Hope Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617)626-8692',
    Website1: 'http://www.metrobostonrlc.org/hope.html',
    Website2: '',
    Email: 'RPoole1@northsuffolk.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Hope Center Recovery Learning Center (RLC) is a peer-run organization dedicated to providing support, education and advocacy to those who self-identify with a mental health and/or substance use history.',
  },
  {
    Name: 'Peer Support Line',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '877-733-7563',
    Website1: 'http://www.metrobostonrlc.org/warm-line.html',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'compassionate community telephone service, staffed by Operators who have lived experience with mental health issues',
  },
  {
    Name: 'Cole Resource Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617-855-3298',
    Website1: 'https://www.coleresourcecenter.org/what-we-do',
    Website2: '',
    Email: 'info@coleresourcecenter.org',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'The Cole Resource Center offers access to a wide variety of programs and resources on mental health challenges and pathways to wellness for peers and families.',
  },
  {
    Name: 'Tufts Medical Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617-636-0219',
    Website1:
      'https://www.tuftsmedicalcenter.org/patient-care-services/Departments-and-Services/Psychiatry/Overview',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'One of the longest-running and most diversified psychiatric services at a general hospital in Boston',
  },
  {
    Name: 'Cambridge Health Alliance',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617) 665-1372',
    Website1: 'https://www.challiance.org/cha-services/mental-health',
    Website2: '',
    Email: 'webmaster@challiance.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Vibrant, innovative health system that serves everyone in need. Offers comprehensive mental health services for all ages and in many languages',
  },
  {
    Name: 'Column Health',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(844) 265-8661',
    Website1: 'http://columnhealth.com/home.php#clinics',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'We offer a full suite of mental health and substance use treatment related services including psychotherapy, integrated lab testing, detoxification, and medication management.',
  },
  {
    Name: 'Codman Square Health Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(617) 822-8142',
    Website1: 'http://www.codman.org/services/behavioral_health.html',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'provides psychiatric, counseling and substance abuse services to children, adolescents, adults, couples, and families.',
  },
  {
    Name: 'The Home for Little Wanderers',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '617-267-3700',
    Website1:
      'http://www.thehome.org/site/PageServer?pagename=child_family_counseling_center',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Child and Family Counseling Center (CFCC) is a licensed outpatient mental health clinic',
  },
  {
    Name: 'Lynn Community Health Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(781) 581‑3900',
    Website1: 'http://www.lchcnet.org/about',
    Website2: '',
    Email: 'http://www.lchcnet.org/contact',
    Category: 'Organization',
    Country: 'USA',
    Description: 'non-profit, multicultural, community health center',
  },
  {
    Name: 'Advocates',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(508) 628-6300',
    Website1: 'https://www.advocates.org/services/mental-health-services',
    Website2: '',
    Email: 'Counseling@Advocates.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'We believe that people in mental health recovery are the foremost experts on their own lives. Ultimately, we strive to help people harness that expertise to lead healthy and fulfilling lives of their own design',
  },
  {
    Name: 'The Brookline Center',
    Address1: '',
    Address2: '',
    City: 'Boston',
    Zip: '',
    Phone: '(617) 277-8107',
    Website1: 'https://www.brooklinecenter.org/our-impact/',
    Website2: '',
    Email: 'info@brooklinecenter.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Brookline Center for Community Mental Health provides outstanding, affordable mental health care and community-based social services that help individuals and families lead healthier, safer, and fuller lives while building the strongest, healthiest community possible',
  },
  {
    Name: 'NYC WELL',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '1-888-692-9355',
    Website1: 'https://nycwell.cityofnewyork.us/en/',
    Website2: '',
    Email:
      'https://nycwell.cityofnewyork.us/en/get-help-now/chat-with-a-counselor-now/',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Call, Text or Chat. NYC Well is your connection to free, confidential mental health support. ',
  },
  {
    Name: 'Hetrick Martin Institute',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '212-674-2400',
    Website1: 'https://hmi.org/lgbtq-youth-agency/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'HMI believes youth service providers should utilize the studies of trauma-informed care, emotional wellness, and positive youth development in serving their communities. HMI believes all systems, policies, and organizations should be curated to make sure LGBTQ youth feel safe and included.',
  },
  {
    Name: 'Vibrant Emotional Health',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(212) 254-0333',
    Website1: 'https://www.vibrant.org/what-we-do/',
    Website2: '',
    Email: 'info@vibrant.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'For 50 years, Vibrant Emotional Health, formerly the Mental Health Association of New York City (MHA-NYC), has been at the forefront of promoting emotional well-being for all people. ',
  },
  {
    Name: 'NAMI NYC',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '212-684-3264',
    Website1: 'https://www.naminycmetro.org/',
    Website2: '',
    Email: 'helpline@naminyc.org',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'NAMI-NYC helps families and individuals affected by mental illness build better lives through education, support, and advocacy.',
  },
  {
    Name: 'Brooklyn Center for Families in Crisis',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(718) 282-0010',
    Website1: 'https://bcfcbrooklyn.org/services/',
    Website2: '',
    Email: 'bcfc@bcfcbrooklyn.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'BCFC is a New York State Office of Mental Health (OMH) article 31 community based outpatient clinic located in Brooklyn providing services for children, adolescents, adults, families, and couples.',
  },
  {
    Name: 'Community Access',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(212) 780-1400',
    Website1: 'https://www.communityaccess.org/connect/connect-to-services',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Community Access expands opportunities for people living with mental health concerns to recover from trauma and discrimination through affordable housing, training, advocacy, and healing-focused services.',
  },
  {
    Name: 'Family Services of Westchester',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '914-937-2320',
    Website1: 'http://www.fsw.org/our-programs',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Family Services of Westchester has been a private, not-for-profit, non-sectarian agency located in Westchester County',
  },
  {
    Name: 'Interborough Development and Consultation Center',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '718-272-1600',
    Website1: 'https://www.interborough.org/about-us/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'we provide a family atmosphere while catering to the local community with a wide array of mental health services. No matter your age, cultural, or religious background, you’ll feel at home with us.',
  },
  {
    Name: 'Karaen Horney Clinic',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '212-838-4333',
    Website1: 'http://www.karenhorneyclinic.org/',
    Website2: '',
    Email: 'TheClinic@karenhorneyclinic.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'In doing so, it has evolved into a vital center for mental health treatment, training and research. The Clinic continues to provide New Yorkers, from all backgrounds, the most professional and compassionate mental health care at an affordable cost.',
  },
  {
    Name: 'Long Island Consultation Center',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(718) 896-3400',
    Website1: 'http://www.liccnewyorkcity.com/mental-health',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Established in 1953, Long Island Consultation Center (LICC) In Queens, New York, is one of the oldest independent, private non-profit mental health centers in the United States',
  },
  {
    Name: 'RAINN',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '800-6564673',
    Website1: 'https://www.rainn.org/',
    Website2: '',
    Email: 'https://hotline.rainn.org/online',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'Call or Chat. RAINN is the nation’s largest anti-sexual violence organization.',
  },
  {
    Name: 'Metropolitan Center for Mental Health',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(212) 362.8755',
    Website1: 'https://www.metropolitancenter.com/services/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Metropolitan Center for Mental Health was founded in 1962 in order to provide low cost psychological treatment for people with emotional problems.',
  },
  {
    Name: 'Moasic Mental Health',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '(718) 796-5300',
    Website1: 'https://mosaicmh.org/for-children-and-families/',
    Website2: '',
    Email: 'info@mosaicmh.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Mosaic MH provides a compassionate network of care as varied as the communities it serves.',
  },
  {
    Name: 'The Jed Foundation',
    Address1: '',
    Address2: '',
    City: 'New York City',
    Zip: '',
    Phone: '212-647-7544',
    Website1: 'https://www.jedfoundation.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'JED is a nonprofit that exists to protect emotional health and prevent suicide for our nation’s teens and young adults.',
  },
  {
    Name: 'Association for Individual Development',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '630) 966-4000',
    Website1:
      'https://www.aidcares.org/outpatient-mental-health-substance-abuse-services/',
    Website2: '',
    Email: 'info@AIDcares.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Association for Individual Development (AID) has served individuals with developmental, intellectual, physical and/or mental health challenges, those who have suffered a trauma and those at risk.',
  },
  {
    Name: 'Community Counseling Crisis Centers',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '773.769.0205',
    Website1: 'https://www.c4chicago.org/about-us/programs',
    Website2: '',
    Email: 'infoc4@c4chicago.org',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'At C4’s community mental health centers, we provide services for children, adolescents and adults. We help people manage mental health problems, overcome substance use and recover from traumas including sexual assault and abuse.',
  },
  {
    Name: 'Friend Family Health Center',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '773.702.0660',
    Website1: 'http://friendfhc.org/medical-comprehensive-services/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Friend Family is a federally qualified health center (FQHC) to continue to ensure access to quality care in medically underserved areas',
  },
  {
    Name: 'Healthcare Alternative Systems',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '(773) 252-3100',
    Website1: 'https://www.hascares.org/programs/?category=mental%2520health',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Healthcare Alternative Systems, Inc. (H.A.S.) is a 501 (c) 3 non-profit organization providing behavioral health services to Chicago and the surrounding communities. The premier behavioral health resource for Chicago’s Latino community, H.A.S. provides culturally competent, bilingual (English and Spanish) services to a diverse population of adolescents and adults from all backgrounds',
  },
  {
    Name: 'New Age Services',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '773 - 542 - 1150',
    Website1: 'http://www.newageservices.org/mental-health.html',
    Website2: '',
    Email: 'Info@newageservices.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'We have a systematic coordination of general and behavioral healthcare.',
  },
  {
    Name: 'Thresholds',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '773) 572-5500',
    Website1: 'http://www.thresholds.org/our-work/youth-services/',
    Website2: '',
    Email: 'contact@thresholds.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Thresholds Youth & Young Adult Services offer groundbreaking programs for adolescents, young adults, families, young mothers, and their children. These programs give young people the skills and supports they need to master mental health challenges while reducing the negative impact of those challenges on relationships, health, education, and career development.',
  },
  {
    Name: 'Triology Behavior Healthcare',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '1-800-322-8400',
    Website1: 'http://www.trilogyinc.org/services/impact',
    Website2: '',
    Email: '',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'a private not-for-profit behavioral healthcare organization with more than 45 years of experience serving people with serious mental illness in the City of Chicago and its surrounding suburbs.',
  },
  {
    Name: 'NAMi Chicago',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '833-626-4244',
    Website1: 'https://namichicago.org/en/about-us/',
    Website2: '',
    Email: 'info@namichicago.org',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'local Chicago affiliate of the National Alliance on Mental Illness',
  },
  {
    Name: 'Center on Halstead',
    Address1: '',
    Address2: '',
    City: 'Chicago',
    Zip: '',
    Phone: '773.472.6469',
    Website1: 'http://www.centeronhalsted.org/',
    Website2: '',
    Email: 'behavioralhealth@centeronhalsted.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      "Center on Halsted is the Midwest's most comprehensive community center dedicated to advancing community and securing the health and well-being of the LGBTQ people of Chicagoland",
  },
  {
    Name: 'Alameda Family Services',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510-629-6300',
    Website1: 'http://www.alamedafs.org/behavioral-health-care-services.html',
    Website2: '',
    Email: 'info@alamedafs.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Alameda Family Services is a human services organization, active in Alameda and the East Bay, whose programs improve the emotional, psychological and physical health of children, youth and families.',
  },
  {
    Name: 'Crisis Suppport Services of Alameda',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '1-800-273-8255',
    Website1: 'https://www.crisissupport.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Founded in 1966, Crisis Support Services of Alameda County offers a broad range of counseling, education and prevention services in a safe environment. CSS’ 24-hour crisis hotline responds to more than 65,000 calls each year',
  },
  {
    Name: 'Alternative Family Services',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '800-300-1022',
    Website1: 'https://www.afs4kids.org/services/mental-health-services/',
    Website2: '',
    Email: 'info@afs4kids.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'AFS is a foster, adoptions and mental health agency that has been creating safe, permanent connections for foster children and youth in the Bay Area since 1978.',
  },
  {
    Name: 'Ann Martin Center',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '(510) 655-7880',
    Website1: 'https://www.annmartin.org',
    Website2: '',
    Email: 'aball@annmartin.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Ann Martin Center is an East Bay nonprofit organization founded in 1963 to support emotional health and school success for at-risk youth.',
  },
  {
    Name: 'Bay Area Community Services',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510.613.0330',
    Website1: 'http://www.bayareacs.org/modernizing-mental-health/',
    Website2: '',
    Email: 'bacs@bayareacs.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'BACS provides behavioral health, housing, and aging services for teens, adults, older adults, and their families.',
  },
  {
    Name: 'Family Paths',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '800-829-3777',
    Website1: 'https://familypaths.org/what-we-do/mental-health-therapy/',
    Website2: '',
    Email: 'info@familypaths.org',
    Category: 'Hotline/Organization',
    Country: 'USA',
    Description:
      'non-profit organization of mental health professionals and dedicated volunteers that provide a number of mental health and supportive services to low income, multi-stressed individuals and familie',
  },
  {
    Name: 'Fred Finch Youth Center',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510-482-2244',
    Website1: 'https://www.fredfinch.org/mental-health-services/',
    Website2: '',
    Email: 'receptionist@fredfinch.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Fred Finch Youth Center has been providing intensive community-based mental health services for youth in homes, schools, and clinics for over two decades.',
  },
  {
    Name: 'La Familia Counseling',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510-881-5921',
    Website1:
      'https://www.lafamiliacounseling.org/outpatient_mental_health_services',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'La Familia has a 40 year history of providing high-quality mental health and community support services in the San Francisco Bay Area.',
  },
  {
    Name: 'California Youth Crisis Hotline',
    Address1: '',
    Address2: '',
    City: 'Statewide',
    Zip: '',
    Phone: '1-800-273-8255',
    Website1: 'http://calyouth.org/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Call, Chat or Text. CCY is a statewide, grassroots nonprofit organization that serves disconnected youth ages 12-24 throughout the state.',
  },
  {
    Name: 'Peers',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '(510) 832-7337',
    Website1: 'https://peersnet.org/about/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'PEERS is a mental health consumer-run organization that specializes in mental health and wellness training, programs and services, and media projects.',
  },
  {
    Name: "Children's Hospital Oakland",
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '(510) 428-3000',
    Website1:
      'https://www.childrenshospitaloakland.org/main/departments-services/mental-health-child-development--40.aspx',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      "At UCSF Benioff Children's Hospital Oakland, the Division of Mental Health & Child Development is the result of the joint effort of multiple disciplines, drawing together the resources and expertise of multiple hospital programs",
  },
  {
    Name: "WestCoast Children's Clinic",
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '(510) 269-9030',
    Website1: 'https://www.westcoastcc.org/what-we-do/clinical-programs/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description: "Nonprofit childrne's community psychology clinic",
  },
  {
    Name: 'West Oakland Health Council',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510-835-9610',
    Website1:
      'https://www.westoaklandhealth.org/care-and-services/integrated-behavioral-health/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'For 50 years, West Oakland Health has championed the health of the East Bay African American community and strives to meet the needs of the region’s growing and diverse population.',
  },
  {
    Name: 'NAMI - Alameda',
    Address1: '',
    Address2: '',
    City: 'Oakland',
    Zip: '',
    Phone: '510-334-7721',
    Website1: 'http://www.nami-alamedacounty.org/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'NAMI helps families and individuals affected by mental illness build better lives through education, support, and advocacy.',
  },
  {
    Name: 'Bring Change to Mind',
    Address1: '',
    Address2: '',
    City: 'National',
    Zip: '',
    Phone: '415-814-8846',
    Website1: 'https://bringchange2mind.org',
    Website2: '',
    Email: 'information@bringchange2mind.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Bring Change to Mind is a nonprofit organization dedicated to encouraging dialogue about mental health, and to raising awareness, understanding, and empathy',
  },
  {
    Name: 'Amanecer Community Counseling Services',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(213) 481-7464',
    Website1: 'https://amanecerla.org/who-we-are/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'ACCS is a trauma-informed, multicultural agency that provides skilled Mental Health and Case management Services for CHILDREN and ADULTS suffering from grief, acting-out behaviors, suicidality, depression, anxiety, trauma-related behavior and cognitive disorders as well as those experiencing domestic violence.  ',
  },
  {
    Name: 'NAMI Los Angeles South Central ',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '310-668-5239',
    Website1: 'https://namica.org/directory/nami-los-angeles-south-central/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'NAMI Los Angeles South Central provides support and referral services to people in need',
  },
  {
    Name: 'NAMI Urban LA',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '323-294-7814',
    Website1: 'https://www.namiurbanla.org/',
    Website2: '',
    Email: 'info@namiurbanla.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'NULA provides education about severe brain disorders, supports increased funding for research, and advocates for adequate health insurance, housing rehabilitation, and jobs for people with serious psychiatric illnesses in communities of color. It also seeks to educate the public about the myths of mental illness to eradicate stigma.',
  },
  {
    Name: 'Didi Hirsch Mental Health Services',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(888) 807-7250',
    Website1: 'https://didihirsch.org/',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Didi Hirsch Mental Health Services has provided free mental health, substance use disorder and suicide prevention services since 1942',
  },
  {
    Name: 'Hathaway-Sycamores',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(626) 395-7100',
    Website1:
      'http://www.hathaway-sycamores.org/programs/outpatientpsychiatric-services/',
    Website2: '',
    Email: 'contact@hathaway-sycamores.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Mental health and welfare agency with 10 locations throughout Southern California',
  },
  {
    Name: 'Star View Community Servicesq',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(310) 221-6336',
    Website1: 'https://www.starsinc.com/programs/',
    Website2: '',
    Email: 'info@starsinc.com',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Provides programs for children, adolescents, and transitional age youth (TAY) who face emotional, behavioral, and learning challenges and have difficulty succeeding in family or community living environments.',
  },
  {
    Name: '​Asian Pacific Counseling and Treatment Centers',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(213) 252-2100',
    Website1: 'https://www.apctc.org/programs-and-services.html',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'APCTC was the first mental health center in Los Angeles County specifically developed to meet the unique needs of rapidly increasing numbers of Asian Pacific immigrants and refugees who were in need of a wide range of mental health services.',
  },
  {
    Name: 'Access LA County Hotline',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '1-800-854-7771',
    Website1:
      'https://dmh.lacounty.gov/our-services/disaster-services/access-hotline/',
    Website2: '',
    Email: '',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'ACCESS operates 24 hours/day, 7 days/week as the entry point for mental health services in Los Angeles County.',
  },
  {
    Name: 'Teen Line',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '310-855-4673',
    Website1: 'https://teenlineonline.org/',
    Website2: '',
    Email: 'TEXT TEEN to 839863',
    Category: 'Hotline',
    Country: 'USA',
    Description:
      'Call or Text. Created in 1980 by a group of mental health professionals who, through their personal work with teenagers, realized that a more inclusive approach to adolescent mental health was needed',
  },
  {
    Name: 'LA LGBT Center - Youth Center',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '323-860-2280',
    Website1: 'https://lalgbtcenter.org/health-services/mental-health',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Los Angeles LGBT Center offers individual, couples, family, and group therapy, as well as psychiatric care',
  },
  {
    Name: 'The Mental Health Client Action Network of Santa Cruz County',
    Address1: '',
    Address2: '',
    City: 'Santa Cruz',
    Zip: '',
    Phone: '(831) 469-0462',
    Website1: 'http://www.mhcan.org/peer-services.html',
    Website2: '',
    Email: 'mail@mhcan.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Peer-run, peer-owned, wellness-oriented community center where people with psychiatric conditions can congregate and socialize in a safe environment, free from the stigma imposed by society',
  },
  {
    Name: 'Southern California Health & Rehabilitation Program',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(310) 667-4770',
    Website1: 'http://www.scharpca.com/index.php',
    Website2: '',
    Email: 'info@scharpca.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      '(SCHARP) was founded February 1994 as a non-profit entity for the purpose of providing mental health and rehabilitation services to vulnerable ethnic minority populations in Southern California',
  },
  {
    Name: 'Los Angeles Child Guidance Clinic',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '(323) 373-2400',
    Website1: 'http://www.lacgc.org/programs.htm',
    Website2: '',
    Email: 'contact@lacgc.org',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'The Clinic’s array of programs are designed to empower the children and young adults of South and Central L.A. to get on track to success – to reach goals in school, build healthy relationships, and enjoy emotional well-being.',
  },
  {
    Name: 'Mental Health America of Los Angeles',
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '562-285-1330',
    Website1: 'https://mhala.org',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Mental Health America of Los Angeles is a non-profit organization serving people living with mental illness in Los Angeles County.',
  },
  {
    Name: "Children's Hospital Los Angeles",
    Address1: '',
    Address2: '',
    City: 'Los Angeles',
    Zip: '',
    Phone: '323-361-7827',
    Website1: 'https://www.chla.org/behavioral-health',
    Website2: '',
    Email: '',
    Category: 'Organization',
    Country: 'USA',
    Description:
      'Provides clinical services and behavioral/psychoeducational assessments',
  },
  {
    Name: 'Mental Wellness Center',
    Address1: '',
    Address2: '',
    City: 'Santa Barbara',
    Zip: '',
    Phone: '805-884-8440',
    Website1: 'http://www.mentalwellnesscenter.org/youth-services',
    Website2: '',
    Email: 'info@mentalwellnesscenter.org.',
    Category: 'Organization',
    Country: 'USA',
    Description:
      ' Works with thousands of people every year to improve and maintain mental wellness',
  },
];
