import React from 'react';
import { Wrapper, Title, Copy } from './help.css';
import Resources from './resources';

function Help() {
  return (
    <Wrapper>
      <Title>Help</Title>
      <Copy>
        <p>
          if you or somebody you know is currently struggling, please please
          please take 10 deep breaths and reach out to somebody. here are a few
          of the many resources available.
        </p>
        <p>
          <strong>hotlines:</strong> if you need to call and talk with someone
          right now, please call one of the numbers
        </p>
        <p>
          <strong>organizations:</strong> the organizations listed are local to
          your towns and have wonderful people ready to support you
        </p>
      </Copy>

      <Resources />
    </Wrapper>
  );
}

export default Help;
