import React, { Fragment } from 'react';
import { Wrapper, Country } from './resources.css';
import Source from './source';
import ResourceData from './resource_data';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';

const Resources = () => {
  const GrouopedResources = groupBy(ResourceData, 'Country');

  return (
    <Wrapper>
      {Object.keys(GrouopedResources)
        .sort()
        .map((key, i) => {
          return (
            <Fragment key={i}>
              <Country>{key}</Country>
              {sortBy(
                sortBy(GrouopedResources[key], o => o.Name),
                o => o.City
              ).map(
                (
                  {
                    Name,
                    Address1,
                    Address2,
                    City,
                    Zip,
                    Phone,
                    Website1,
                    Website2,
                    Email,
                    Category,
                    Country,
                    Description,
                  },
                  k
                ) => {
                  return (
                    <Source
                      key={k}
                      Name={Name}
                      Address1={Address1}
                      Address2={Address2}
                      City={City}
                      Zip={Zip}
                      Phone={Phone}
                      Website1={Website1}
                      Website2={Website2}
                      Email={Email}
                      Category={Category}
                      Country={Country}
                      Description={Description}
                    />
                  );
                }
              )}
            </Fragment>
          );
        })}
    </Wrapper>
  );
};

export default Resources;
