import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const Country = styled.div`
  background-color: #2000ff;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 9px;
`;
