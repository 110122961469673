import styled, { css } from 'styled-components';
import { Link } from 'react-scroll';
import { Link as Glink } from 'gatsby';

export const LauvLogo = styled.h1`
  font-family: 'Folio';
  font-weight: 500;
  font-style: normal;
  color: #fff;
  font-size: 2.2rem;
  text-transform: lowercase;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  padding: 0 20px;
  box-sizing: border-box;

  .hamburgerBtn {
    display: none;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    padding: 0;

    /* & > img {
      width: 30%;
      margin-bottom: 30px;
    } */
  }
`;

export const Container = styled.span`
  display: inline;

  .navWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Styling of overlay */
  .bm-overlay {
    ${({ isOpen }) =>
      isOpen &&
      css`
        top: 0 !important;
      `} background: rgba(0, 0, 0, 0.3);
  }
`;

export const TextLinks = styled.div`
  display: flex !important;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
  justify-content: space-between;
  font-family: 'BDRmono';
  font-size: 0.75em;
  box-sizing: border-box;

  @media (max-width: 750px) {
    margin-top: 10px;
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.5;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      display: flex !important;
      width: auto !important;
      justify-content: flex-start;
      align-items: center;
      outline: none;
      margin: 0 auto 30px;

      a {
        margin-bottom: 10px;

        &:hover {
          color: #fff;
        }
      }
    `};
`;

export const MainNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  a {
    color: #000 !important;
  }

  @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const GLink = styled(Glink)`
  color: #000;
  text-transform: lowercase;
  text-decoration: none;
  transition: all 180ms ease-out;
  font-family: 'Folio';
  font-weight: 500;
  font-style: normal;
  font-size: 1.4rem;
  color: #fff;
  border-bottom: 2px solid transparent;
  margin-left: 25px;
  img {
    width: 100%;
    max-width: 100px;
  }
  @media (max-width: 750px) {
    margin-left: 10px;
    margin-right: 10px;
    &:first-child {
      margin-right: 0;
    }
  }

  &:hover {
    color: #fff;
    border-bottom-color: transparent;
  }

  @media (max-width: 600px) {
    display: none;
  }

  ${({ showmobile }) =>
    showmobile &&
    css`
      @media (max-width: 600px) {
        display: block !important;
        margin-left: 0;
        color: #fff;
        font-size: 1.7em;
        margin-bottom: img {
          width: 150px;
        }
      }
    `}
`;

export const NLink = styled(Link)`
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 180ms ease-out;
  font-size: 1.4em;
  font-family: 'Folio';
  font-weight: 500;
  font-style: normal;
  color: #fff;
  margin-left: 25px;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  @media (max-width: 750px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  &:hover {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.4);
  }

  @media (max-width: 600px) {
    display: none;

    ${({ showmobile }) =>
      showmobile &&
      css`
        @media (max-width: 600px) {
          display: block !important;
          color: #fff;
          margin-left: 0;
          margin-bottom: 15px;
          font-size: 1.7em;
        }
      `}
  }
`;

export const ALink = styled.a`
  color: #000;
  text-transform: lowercase;
  text-decoration: none;
  transition: all 180ms ease-out;
  font-family: 'Folio';
  font-weight: 500;
  font-size: 1.4rem;
  font-style: normal;
  color: #fff;
  margin-left: 25px;
  color: #fff;
  padding-right: 15px;
  border-bottom: 2px solid transparent;
  @media (max-width: 750px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  &:hover {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.4);
  }

  @media (max-width: 600px) {
    display: none;
    ${({ showmobile }) =>
      showmobile &&
      css`
        @media (max-width: 600px) {
          display: block !important;
          margin-left: 0;
          margin-top: 15px;
          color: #fff;
          font-size: 1.7em;
        }
      `}
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 225px;
  margin-top: 20px;

  @media (max-width: 750px) {
    margin-top: 5px;
    width: 100%;
    justify-content: center;
    a {
      margin: 0 25px;
    }
  }

  a {
    margin: 0 10px;
    transition: opacity 180ms ease-out;
    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      display: flex !important;
      width: 80%;
      margin: 20px auto 0;
      position: absolute;
      bottom: 50px;

      img {
        filter: brightness(100%);
      }
    `};
`;

export const MobileButton = styled.button`
  display: none;
  @media (max-width: 600px) {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 22px;
    left: 16px;
    z-index: 300;
    polygon {
      fill: #fff;
    }
  }
`;

export const MobileContainer = styled.div`
  display: none;
  @media (max-width: 600px) {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 50vw;
    height: 100vh;
    z-index: 200;
    transform: translateX(-100vw);
    transition: transform 100ms ease-out;
    background: #122437;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateX(0);
      `}
  }
`;

export const MobileNavWrapper = styled.div`
  position: absolute;
  left: 14px;
  top: 100px;
  text-align: left;
`;

export const LogoImg = styled.img`
  width: 200px;
  filter: invert(1);
`;
