import React from 'react';
import Layout from 'components/layout';
import Nav from 'components/nav';
import HelpApp from 'components/help';

function Help() {
  return (
    <Layout>
      <Nav />
      <div
        style={{
          backgroundColor: '#2000ff',
          height: '100%',
        }}
      >
        <HelpApp />
      </div>
    </Layout>
  );
}

export default Help;

/*

background-image: linear-gradient(-133deg, #000000 0%, #012F47 80%);

*/
