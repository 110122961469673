/* eslint-disable */

import React, { Component, Fragment } from 'react';
import {
  Container,
  MobileButton,
  MobileNavWrapper,
  NLink,
  MainNav,
  Box,
  Social,
  TextLinks,
  ALink,
  GLink,
  MobileContainer,
  LauvLogo,
  LogoImg,
} from './nav.css';
import Instagram from '../../../static/instagramBlack.svg';
import Twitter from '../../../static/twitterBlack.svg';
import Facebook from '../../../static/facebookBlack.svg';
import Spotify from '../../../static/spotify.svg';
import Apple from '../../../static/apple.svg';
import Amazon from '../../../static/amazon.svg';
import Youtube from '../../../static/youtube.svg';

import Logo from '../../../static/Logo.png';

class Nav extends Component {
  state = {
    isOpen: false,
    deep: false,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (document.location.pathname !== '/') {
        this.setState({ deep: true });
      }
      document.addEventListener('keydown', this.onKeyDown);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keydown', this.onKeyDown);
    }
  }

  disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  menuChange = e => {
    if (!e.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState(state => {
      return {
        isOpen: !state.isOpen,
      };
    });
  };

  render() {
    if (typeof document !== 'undefined') {
      this.disableScrolling(this.state.isOpen);
    }

    return (
      <Container>
        <Box>
          <MainNav>
            <LogoImg src={Logo} />

            <div className="navWrapper">
              <TextLinks desktop>
                <Fragment>
                  {/* <ALink
                    href="https://shop.lauvsongs.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Order
                  </ALink> */}
                  {/* <NLink
                    to="music"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    isDynamic={true}
                    href="#"
                  >
                    Listen
                  </NLink>
                  <NLink
                    to="videos"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    isDynamic={true}
                    href="#"
                  >
                    Watch
                  </NLink> */}
                  {/* <NLink
                    to="tour"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    isDynamic={true}
                    href="#"
                  >
                    Tour 
                  </NLink>*/}
                </Fragment>
                <GLink to="/#tour">Tour</GLink>
                <GLink to="/help">Help</GLink>
                <ALink
                  href="https://shopus.lauvsongs.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Store
                </ALink>
              </TextLinks>
              <Social>
                <a
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/lauvsongs/"
                  target="_blank"
                >
                  <img src={Instagram} alt="Lauv on Instagram" />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/lauvsongs/"
                  target="_blank"
                >
                  <img src={Facebook} alt="Lauv on Facebook" />
                </a>{' '}
                <a
                  rel="noopener noreferrer"
                  href="https://twitter.com/lauvsongs"
                  target="_blank"
                  style={{ position: 'relative', top: '1px' }}
                >
                  <img src={Twitter} alt="Lauv on Twitter" />
                </a>
              </Social>
            </div>
          </MainNav>
        </Box>
        <MobileButton onClick={this.toggleMenu}>
          {this.state.isOpen ? (
            <svg width="18" height="18" viewBox="0 0 18 18">
              <polygon
                fillRule="evenodd"
                fill="#ffffff"
                points="9 7.586 1.929 .515 .515 1.929 7.586 9 .515 16.071 1.929 17.485 9 10.414 16.071 17.485 17.485 16.071 10.414 9 17.485 1.929 16.071 .515"
              />
            </svg>
          ) : (
            <svg width="22" height="14" viewBox="0 0 22 14">
              <path
                fill="#ffffff"
                d="M1.25 13.753C.835786438 13.753.5 13.4172136.5 13.003.5 12.5887864.835786438 12.253 1.25 12.253L20.75 12.253C21.1642136 12.253 21.5 12.5887864 21.5 13.003 21.5 13.4172136 21.1642136 13.753 20.75 13.753L1.25 13.753zM1.25 7.753C.835786438 7.753.5 7.41721356.5 7.003.5 6.58878644.835786438 6.253 1.25 6.253L20.75 6.253C21.1642136 6.253 21.5 6.58878644 21.5 7.003 21.5 7.41721356 21.1642136 7.753 20.75 7.753L1.25 7.753zM1.25 1.753C.835786438 1.753.5 1.41721356.5 1.003.5.588786438.835786438.253 1.25.253L20.75.253C21.1642136.253 21.5.588786438 21.5 1.003 21.5 1.41721356 21.1642136 1.753 20.75 1.753L1.25 1.753z"
              />
            </svg>
          )}
        </MobileButton>
        <MobileContainer isOpen={this.state.isOpen}>
          <MobileNavWrapper>
            <NLink
              to="music"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              isDynamic={true}
              href="#"
              onClick={this.toggleMenu}
            >
              Listen
            </NLink>
            <NLink
              to="videos"
              onClick={this.toggleMenu}
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              isDynamic={true}
              href="#"
            >
              Watch
            </NLink>
            <NLink
              to="tour"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              isDynamic={true}
              onClick={this.toggleMenu}
              href="#"
            >
              Tour
            </NLink>
            <GLink style={{ marginBottom: '15px' }} showmobile to="/#tour">
              Tour
            </GLink>
            <GLink showmobile to="/help">
              Help
            </GLink>
            <ALink
              href="https://shopus.lauvsongs.com/"
              target="_blank"
              rel="noopener noreferrer"
              showmobile
            >
              Store
            </ALink>
          </MobileNavWrapper>
          <Social mobile>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/lauvsongs/"
              target="_blank"
            >
              <img src={Instagram} alt="Lauv on Instagram" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/lauvsongs/"
              target="_blank"
            >
              <img src={Facebook} alt="Lauv on Facebook" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/lauvsongs"
              target="_blank"
              style={{ position: 'relative', top: '1px' }}
            >
              <img src={Twitter} alt="Lauv on Twitter" />
            </a>
          </Social>
        </MobileContainer>
      </Container>
    );
  }
}

export default Nav;
