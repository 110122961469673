import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #e7e7e7;

  & > div {
    width: calc(100% / 3);
    @media (max-width: 700px) {
      width: 100%;
      &:nth-child(2) {
        margin: 15px 0;
      }
    }
  }

  .org {
    font-size: 1.5em;
    margin-bottom: 3px;
    letter-spacing: 0.02em;
  }

  .city {
    text-transform: uppercase;
    color: #2000ff;
    font-size: 1.2em;

    span {
      color: #000;
      opacity: 0.3;
    }
  }

  p {
    opacity: 0.8;
    font-size: 1.1em;
    line-height: 1.3;
  }

  .contacts {
    a {
      display: block;
      font-size: 1.2em;
      text-decoration: none;
      color: #2000ff;

      svg {
        margin-right: 8px;
      }
      &:first-child {
        margin-bottom: 2px;
      }
      &:hover {
        color: #000;
      }
    }
  }
`;
